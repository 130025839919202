<template>
  <v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('dialogMessages.itemDeleteConfirmation')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn class="success" @click="downloadCsv">
      {{ $t('buttons.exportClaims') }}
    </v-btn>
    <v-row no-gutters>
      <v-col cols="3">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="searchString"
          :placeholder="$t('placeholders.search')"
          clearablef
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="10" md="8">
        <v-chip-group
          v-model="filters.selection"
          multiple
          active-class="primary--text"
        >
          <v-chip v-if="!isClient" value="Followed" @click="handleFilterClick">
            {{ $t('filters.followed') }}
          </v-chip>
          <v-chip value="Open" @click="handleFilterClick">
            {{ $t('filters.open') }}
          </v-chip>
          <v-chip value="Closed" @click="handleFilterClick">
            {{ $t('filters.closed') }}
          </v-chip>
          <v-chip v-if="!isClient" value="New" @click="handleFilterClick">
            {{ $t('filters.new') }}
          </v-chip>
          <v-chip value="2023" @click="handleFilterClick"> 2023 </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-autocomplete
          dense
          v-if="clients"
          :items="clientSelectItems"
          v-model="filters.client"
          :label="$t('filters.client')"
          @change="handleFilterClick"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          dense
          v-if="stages"
          :items="stageItems"
          v-model="filters.stage"
          :label="$t('filters.stage')"
          @change="handleFilterClick"
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-menu
          v-model="fromDatepicker_show"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="filters.fromDate"
              :label="$t('filters.reportedDateFrom')"
              v-on="on"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.fromDate"
            @input="fromDatepicker_show = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="toDatepicker_show"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="filters.toDate"
              :label="$t('filters.reportedDateTo')"
              v-on="on"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.toDate"
            @input="toDatepicker_show = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pagination.pages || 1"
        @input="handlePaginationInput"
      ></v-pagination>
    </div>
    <v-data-table
      class="elevation-1"
      :headers="_headersWithActions"
      :items="sortedClaimsWithDuration"
      :item-class="classChecker"
      :loading="isLoading"
      :header-props="headerProps"
      :footer-props="footerOptions"
      :server-items-length="pagination.records || -1"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination.sync="pagination"
          :options="pagination"
          @update:options="updateOptions"
          :items-per-page-options="footerOptions.itemsPerPageOptions"
        />
      </template>
      <template v-slot:item.id="{ item }">
        <router-link :to="`/other/${item.id}/`">
          <span>{{
            item.claimNumber ? item.claimNumber : item.id
          }}</span></router-link
        >
      </template>

      <template v-slot:item.amountReserve="{ item }">
        <span>
          {{ formatAmount(item.amountReserve) }}
        </span>
      </template>
      <template v-slot:item.outcome="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ truncate(item.outcome, 8) }}
            </span>
          </template>
          <span>
            {{ item.outcome }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.stage.displayName="{ item }">
        <span :class="stageColor(item.stage.displayName)">
          {{ $t(`stages["${item.stage.displayName}"]`) }}
        </span>
      </template>
      <template v-slot:item.stageChangeDate="{ item }">
        <span>
          {{ item.stageChangeDate ? formatDate(item.stageChangeDate) : '' }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="isAdmin" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pagination.pages || 1"
        @input="handlePaginationInput"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import {
  getTimeDifferenceInDays,
  removeUnwantedSeparators,
  getTimeDifferenceInDaysUntilNow,
} from './../utils/formattingHelpers'
import { exportCsv } from './../utils/OTHERExportCSV'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isLoading: true,
      hideHeadersFromClients: ['duration'],
      fromDatepicker_show: false,
      toDatepicker_show: false,
      dialogDelete: false,
      dialog: false,
      editedIndex: -1,
      editedItem: {},
      filters: {
        selection: [],
        client: '',
        fromDate: '',
        toDate: '',
        stage: '',
      },
      searchString: '',
      filtersLoaded: false,
      options: {},
      page: 1,
      itemsPerPage: 25,
      footerOptions: {
        itemsPerPageOptions: [25, 50, 100, -1],
      },
    }
  },
  //TODO: Search,filters, export
  created() {
    this.$store.dispatch('client/getClients')
  },
  mounted() {
    this.$store.dispatch('stage/getStages', 9)
    this.localStorageLoad()
  },
  beforeDestroy() {
    this.$store.dispatch('mtpl/clearClaims')
    localStorage.setItem('OTHERFilters', JSON.stringify(this.filters))
    localStorage.setItem('OTHERItemsPerPage', JSON.stringify(this.itemsPerPage))
    localStorage.setItem('OTHERPage', JSON.stringify(this.page))
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      pagination: (state) => state.mtpl.pagination,
      mtpls: (state) => state.mtpl.mtpls,
      user: (state) => state.user.user,
      clients: (state) => state.client.clients,
      stages: (state) => state.stage.stages,
    }),

    headers() {
      return [
        {
          text: this.$i18n.t('headers.stage'),
          value: 'stage.displayName',
          sortable: false,
        },
        {
          text: this.$i18n.t('headers.stageChangeDate'),
          value: 'stageChangeDate',
          sortable: false,
        },
        {
          text: this.$i18n.t('headers.client'),
          value: 'client.name',
          sortable: false,
        },
        {
          text: this.$i18n.t('headers.claim'),
          value: 'id',
          sortable: false,
        },
        {
          text: this.$i18n.t('headers.reserve'),
          value: 'amountReserve',
        },
        {
          text: this.$i18n.t('headers.outcome'),
          value: 'outcome',
          sortable: false,
        },
        {
          text: this.$i18n.t('headers.duration'),
          value: 'duration',
          sortable: false,
        },
      ]
    },
    stageItems() {
      let stages = this.stages
      return stages.map((stage) => {
        return {
          value: stage.id,
          text: stage.displayName,
        }
      })
    },
    headersWithActions() {
      return [
        ...this.headers,
        {
          text: this.$i18n.t('headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    filteredClaims() {
      let conditions = []

      if (conditions.length > 0 && this.mtpls.claims) {
        return this.mtpls.claims.filter((claim) => {
          return conditions.every((condition) => {
            return condition(claim)
          })
        })
      }

      return this.mtpls.claims
    },
    _headersWithActions() {
      return this.headersWithActions.filter(
        (h) => !this.hideHeadersFromClients.includes(h.value) || !this.isClient
      )
    },

    sortedClaimsWithDuration() {
      return this.filteredClaims.map((c) => {
        return {
          ...c,
          duration: c.processDuration
            ? getTimeDifferenceInDays(c.reportedDate, c.processDuration)
            : getTimeDifferenceInDaysUntilNow(c.reportedDate),
        }
      })
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
    headerProps() {
      return {
        sortByText: this.$i18n.t(`dataTable.sortBy`),
      }
    },
    clientSelectItems() {
      return this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
  },
  methods: {
    classChecker(item) {
      if (item.createdByClient) {
        return 'custom-bg'
      } else {
        return ''
      }
    },
    filterFollowedClaims(item) {
      return item.followers.map((f) => f.id).includes(this.user.id)
    },
    filterClosedClaims(item) {
      return item.stage.isFinal
    },
    filterOpenClaims(item) {
      return !item.stage.isFinal
    },
    filterCurrentYearClaims(item) {
      const yearToCheck = 2023
      return dayjs(item.reportedDate).isSame(`${yearToCheck}`, 'year')
    },
    filterNewClientClaims(item) {
      return (
        item.createdByClient &&
        (item.claimNumber == null || item.claimNumber == '')
      )
    },
    getCurrentTimeString() {
      let timestamp = new Date()
      return (
        '' +
        timestamp.getFullYear() +
        timestamp.getMonth() +
        timestamp.getDay() +
        timestamp.getHours() +
        timestamp.getMinutes() +
        timestamp.getSeconds()
      )
    },
    filterClaimClient(item) {
      return item.client.id == this.filters.client
    },
    filterSearch(item) {
      return (
        item.client?.name
          .toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.id.toString().includes(this.searchString) ||
        item.driver?.name
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.trailerRegistration
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.vehicle?.registration
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.stage?.displayName
          ?.toLowerCase()
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        this.formatDate(item.incidentDate).includes(this.searchString) ||
        item.location?.includes(this.searchString) ||
        item.insuranceProvider?.includes(this.searchString) ||
        item.incidentLocation
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.description
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.insuranceClaimNumber
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.outcome?.toString().includes(this.searchString) ||
        (item.circumstance?.name &&
          this.$i18n
            .t(`circumstances["${item.circumstance?.name}"]`)
            ?.toLowerCase()
            .includes(this.searchString.toLowerCase())) ||
        (item.stage?.displayName &&
          this.$i18n
            .t(`stages["${item.stage?.displayName}"]`)
            .toLowerCase()
            .includes(this.searchString.toLowerCase()))
      )
      /*
       */
    },
    filterClaimFromDate(item) {
      let fromDate = dayjs(this.filters.fromDate)
      let createdDate = dayjs(item.reportedDate)
      return createdDate.diff(fromDate) >= 0
    },
    filterClaimToDate(item) {
      let toDate = dayjs(this.filters.toDate)
      let createdDate = dayjs(item.reportedDate)
      return toDate.diff(createdDate) >= 0
    },
    handlePaginationInput(page) {
      this.page = page
      this.loadData()
    },
    localStorageLoad() {
      let storagePromises = []

      // Handle Page
      if (localStorage.getItem('OTHERPage')) {
        let JSONPage = localStorage.getItem('OTHERPage')
        try {
          let page = JSON.parse(JSONPage)
          if (parseInt(page)) {
            storagePromises.push(
              new Promise((resolve) => {
                this.$nextTick(() => {
                  this.page = page
                  this.options.page = page
                  resolve() // Resolve when page is set
                })
              })
            )
          }
        } catch (error) {
          console.error('Error parsing OTHERPage from localStorage', error)
        }
      }

      // Handle Items Per Page
      if (localStorage.getItem('OTHERItemsPerPage')) {
        let JSONItemsPerPage = localStorage.getItem('OTHERItemsPerPage')
        try {
          let itemsPerPage = JSON.parse(JSONItemsPerPage)
          if (parseInt(itemsPerPage)) {
            storagePromises.push(
              new Promise((resolve) => {
                this.$nextTick(() => {
                  this.itemsPerPage = itemsPerPage
                  this.options.itemsPerPage = itemsPerPage
                  resolve() // Resolve when itemsPerPage is set
                })
              })
            )
          }
        } catch (error) {
          console.error(
            'Error parsing OTHERItemsPerPage from localStorage',
            error
          )
        }
      }

      if (localStorage.getItem('OTHERFilters')) {
        let JSONFilters = localStorage.getItem('OTHERFilters')
        try {
          let filters = JSON.parse(JSONFilters)
          storagePromises.push(
            new Promise((resolve) => {
              this.$nextTick(() => {
                this.filters = Object.assign({}, this.filters, filters)
                resolve() // Resolve when filters are set
              })
            })
          )
        } catch (error) {
          console.error('Error parsing OTHERFilters from localStorage', error)
        }
      } else {
        this.filters.selection = this.isClient ? ['Open'] : ['Followed']
      }
      Promise.all(storagePromises).then(() => {
        this.$nextTick(() => {
          this.filtersLoaded = true
          this.loadData()
        })
      })
    },
    loadData() {
      if (this.filtersLoaded) {
        let pageSize
        if (this.itemsPerPage == -1) {
          pageSize = 10000
        } else {
          pageSize = this.itemsPerPage
        }
        this.$store.dispatch('mtpl/clearClaims')
        this.$nextTick(() => {
          this.isLoading = true
        })

        this.$store
          .dispatch('mtpl/getClaims', {
            pipelineId: 9,
            orderBy: 'reportedDate',
            page: this.page || 1,
            pageSize: pageSize,
            filters: this.filters,
            claimSearch: this.searchString?.length > 2 ? this.searchString : '',
          })
          .then(
            this.$nextTick(() => {
              this.isLoading = false
            })
          )
      }
    },
    downloadCsv() {
      let dataToExport = this.sortedClaimsWithDuration.map((c) => ({
        client: this.nullCheck(c.client.name),
        claim: c.claimNumber ? c.claimNumber : c.id,
        otherClaimNumber: c.otherClaimNumber ? c.otherClaimNumber : '',
        amountReserve: this.formatAmount(this.nullCheck(c.amountReserve)),
        amountReduced: this.formatAmount(this.nullCheck(c.amountReduced)),
        amountRejected: this.formatAmount(this.nullCheck(c.amountRejected)),
        outcome: removeUnwantedSeparators(this.nullCheck(c.outcome)),
        description: removeUnwantedSeparators(this.nullCheck(c.description)),
        stage: this.nullCheck(c.stage.displayName),
        stageChangeDate: c.stageChangeDate
          ? this.formatDate(c.stageChangeDate)
          : '',
      }))

      // exportCsv(dataToExport)
      const link = document.createElement('a')
      link.setAttribute('href', exportCsv(dataToExport))
      link.setAttribute(
        'download',
        `claimExport ${this.getCurrentTimeString()}.csv`
      )
      link.click()
    },

    nullCheck(value) {
      return value ? value : ''
    },
    truncate(input, length = 5) {
      if (input != null) {
        return input.length > length
          ? `${input.substring(0, length)}...`
          : input
      }
      return input
    },

    isEmpty(value) {
      if (value != null && value.length > 0) {
        return 'Y'
      }
      return 'N'
    },
    formatAmount(amount) {
      if (amount > 0) {
        return amount / 100
      } else {
        return amount
      }
    },
    claimDuration(item) {
      return item.processDuration
        ? getTimeDifferenceInDays(item.reportedDate, item.processDuration)
        : getTimeDifferenceInDaysUntilNow(item.reportedDate)
    },
    stageColor(displayName) {
      switch (displayName) {
        case 'Missing contact details':
          return 'red--text'
        case 'Large claim':
          return 'red--text'
        case 'Reported by insurer':
          return 'red--text'
        case 'Agreed, but unpaid':
          return 'orange--text'
        case 'Paid/Closed':
          return 'green--text'
        default:
          return ''
      }
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    deleteItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.dispatch('mtpl/deleteClaim', this.editedIndex)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.editedIndex = this.mtpls.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    circumstanceTooltip(item) {
      return item.circumstance
        ? this.$i18n.t(`circumstances.${item.circumstance.name}`)
        : ''
    },

    circumstanceName(item) {
      return item.circumstance
        ? this.truncate(
            this.$i18n.t(`circumstances.${item.circumstance.name}`),
            8
          )
        : ''
    },
    handleFilterClick() {
      this.page = 1
      this.options.page = 1
    },
  },
  watch: {
    options(options) {
      this.page = options.page
      this.itemsPerPage = options.itemsPerPage
      this.loadData()
    },
    searchString() {
      if (this.searchString?.length > 2) {
        this.loadData()
      } else if (!this.searchString) {
        this.loadData()
      }
    },
    filters: {
      handler() {
        localStorage.setItem('OTHERFilters', JSON.stringify(this.filters))
        localStorage.setItem(
          'OTHERItemsPerPage',
          JSON.stringify(this.itemsPerPage)
        )
        localStorage.setItem('OTHERPage', JSON.stringify(this.page))

        this.loadData()
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
.custom-bg {
  background-color: #f7ebe3;
}
</style>
